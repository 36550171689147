export class GaleriaVideo {

    id: number = 0;
    descricao: string = '';
    video: string = '';
    ordem: number = 99;
    situacao: string = 'A';
    [x: string]: any;

    constructor() {
        Object.assign(this);
    }
}