export class Blog {

    id: 1;
    titulo: string = ''
    chamada: string = ''
    texto: string = ''
    data: Date = new Date();
    slug: string = ''
    imagem: string = ''
    autor: string = ''
    imagemAutor: string = ''
    situacao: string = 'A';
    [x: string]: any;

    constructor() {
        Object.assign(this);
    }
}