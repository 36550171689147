export class CadastroGeral {

    id: number = 0;
    imagemDestaque: string = '';
    textoSistema: string = '';
    textoFaleConosco: string = '';
    textoClientes: string = '';
    textoDepoimentos: string = '';
    imagemHeaderBlog: string = '';
    textoBlog: string = '';
    textoEntreEmContato: string = '';
    [x: string]: any;
    constructor() {
        Object.assign(this);
    }
}