import { SistemaBeneficio } from "./sistema-beneficio.model";
import { SistemaModulo } from "./sistema-modulo.model";
import { SistemaPlano } from "./sistema-plano.model";
import { SistemaTela } from "./sistema-tela.model";

export class Sistema {

    id: number = 0;
    nome: string = '';
    resumo: string = '';
    imagemHeader: string = '';
    icone: string = '';
    titulo1: string = '';
    texto1: string = '';
    imagem1: string = '';
    titulo2: string = '';
    texto2: string = '';
    imagem2: string = '';
    textoBeneficios: string = '';
    textoModulos: string = '';
    textoPlanos: string = '';
    slug: string = '';
    situacao: string = 'A';
    modulos: SistemaModulo[] = [];
    planos: SistemaPlano[] = [];
    beneficios: SistemaBeneficio[] = [];
    telas: SistemaTela[] = [];
    order: number = 99;
    [x: string]: any;

    constructor() {
        Object.assign(this);
    }
}