export class Sobre {

    id: number = 0;
    imagemHeader: string = '';
    titulo: string = '';
    descricao: string = '';
    titulo1: string = '';
    titulo2: string = '';
    titulo3: string = '';
    titulo4: string = '';
    texto1: string = '';
    texto2: string = '';
    texto3: string = '';
    texto4: string = '';
    imagem1: string = '';
    imagem2: string = '';
    imagem3: string = '';
    imagem4: string = '';
    [x: string]: any;

    constructor() {
        Object.assign(this);
    }
}